<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Contabilidade">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment" titulo="Imobilizado">
  </app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center" style="margin-bottom: 0;">Os campos marcados com (*) são de preenchimento obrigatório.</p>

    <p class="text-center">Caso não tenha o template de solicitação de movimentação ativo imobilizado,
      <a href="./assets/baixa-imobilizado-v1.xlsx" target="_blank"> clique aqui</a>.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nome" class="col-sm-4 col-form-label">*Digite seu nome: </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome')?.errors?.required && formulario.get('nome')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>

      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-sm-4 col-form-label">*Digite seu e-mail: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.email && formulario.get('email')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="nome_da_empresa" class="col-sm-4 col-form-label">*Nome da Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="nome_da_empresa">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_da_empresa')?.errors?.required && formulario.get('nome_da_empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="tratativa" class="col-sm-4 col-form-label">Tipo de Tratativa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="tratativa">
          <option *ngFor="let tratativa of tratativas" [value]="tratativa.nome">{{tratativa.nome}}</option>
        </select>
      </div>
    </div> -->


    <div class="form-group row">
      <label for="codigo_sap" class="col-sm-4 col-form-label">*Código Sap </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="codigo_sap">
          <option *ngFor="let codigo of codigosSap" [value]="codigo.nome">{{codigo.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('codigo_sap')?.errors?.required && formulario.get('codigo_sap')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_da_baixa" class="col-sm-4 col-form-label">*Data da baixa</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_da_baixa">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_da_baixa')?.errors?.required && formulario.get('data_da_baixa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="numero_do_bem" class="col-sm-4 col-form-label">Número do bem</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="numero_do_bem">
      </div>
    </div>


    <div class="form-group row">
      <label for="numero_de_nota_fiscal" class="col-sm-4 col-form-label">*Número de nota fiscal</label>
      <div class="col-sm-8">
        <input type="number" class="form-control" formControlName="numero_de_nota_fiscal" (change)="mostraValor($event)">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_de_nota_fiscal')?.errors?.required && formulario.get('numero_de_nota_fiscal')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="codigo_de_ativo_no_sistema" class="col-sm-4 col-form-label">*Código de Ativo no Sistema</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="codigo_de_ativo_no_sistema">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('codigo_de_ativo_no_sistema')?.errors?.required && formulario.get('codigo_de_ativo_no_sistema')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">

      <label for="tipo_de_transa_o" class="col-sm-4 col-form-label">*Tipo de Transação </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="tipo_de_transa_o">
          <option *ngFor="let tipo of tiposTransacao" [value]="tipo.nome">{{tipo.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('tipo_de_transa_o')?.errors?.required && formulario.get('tipo_de_transa_o')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="recebimento_financeiro_atrav_s_de_leil_o"
      class="col-sm-4 col-form-label">
      *Recebimento financeiro através de leilão?</label>
      <div class="col-sm-4">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='recebimento_financeiro_atrav_s_de_leil_o' value="Sim" (change)="onChangeLeilao($event)"class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='recebimento_financeiro_atrav_s_de_leil_o' value='Não' (change)="onChangeLeilao($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().recebimento_financeiro_atrav_s_de_leil_o == 'Sim'">
      <label for="incluirAnexosComprovante" class="col-sm-4 col-form-label">*Comprovante de pagamento </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="incluirAnexosComprovante" id="formFileMultiple" (change)="saveIncluirAnexoComprovante($event)" (change)="mostraValor($event)" multiple="multiple">
      </div>
    </div>
    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="incluirAnexosParmComprovante.length!=0">
      <div class="card-header">Comprovante de pagamento</div>
      <ul class="list-group list-group-flush" *ngFor="let file of incluirAnexosParmComprovante">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileIncluirAnexoComprovante(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="form-group row">
      <label for="valor_do_faturamento_valor_total_da_venda_dos_itens" class="col-sm-4 col-form-label">*Valor do Faturamento (Valor total da venda dos itens)</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_faturamento_valor_total_da_venda_dos_itens" (change)="mostraValor($event)">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_faturamento_valor_total_da_venda_dos_itens')?.errors?.required && formulario.get('valor_do_faturamento_valor_total_da_venda_dos_itens')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="pdf_nota_fiscal_de_origem_em_anexo"
      class="col-sm-4 col-form-label">
      *PDF da nota fiscal de origem está em anexo?</label>
      <div class="col-sm-4">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='pdf_nota_fiscal_de_origem_em_anexo' value="Sim" (change)="mostraValor($event)"class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='pdf_nota_fiscal_de_origem_em_anexo' value='Não' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div>

    <div class="form-group row">
      <label for="form_solicitao_emisso_nota_fiscal_anexado_e_preenchido" class="col-sm-4 col-form-label">
        *Formulário de solicitação de emissão de nota fiscal está anexado e preenchido?</label>
      <div class="col-sm-4 mt-2">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='form_solicitao_emisso_nota_fiscal_anexado_e_preenchido' value="Sim" (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='form_solicitao_emisso_nota_fiscal_anexado_e_preenchido' value='Não' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div>
    <div class="form-group row">
      <label for="form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto"
      class="col-sm-4 col-form-label">
      *Formulário de autorização da contabilidade para emissão da nota fiscal está em anexo e assinado pela gestão?</label>
      <div class="col-sm-4">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto' value="Sim" (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto' value='Não' (change)="mostraValor($event)" class='radio-btn'
          >&nbsp;Não
        </label>
      </div>
    </div>

    <div class="form-group row">
      <label for="incluirAnexos" class="col-sm-4 col-form-label">*Incluir anexos </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="incluirAnexos" id="formFileMultiple" (change)="saveIncluirAnexo($event)" (change)="mostraValor($event)" multiple="multiple">
      </div>
    </div>
    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="incluirAnexosParm.length!=0">
      <div class="card-header">Incluir anexos</div>
      <ul class="list-group list-group-flush" *ngFor="let file of incluirAnexosParm">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileIncluirAnexo(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="mensagem-atencao" *ngIf="this.formulario.getRawValue().form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto == 'Sim'" >
      <h6 class="text-center">Atenção!</h6>
      <p class="text-center">É necessário que o(s) formulário(s) esteja(m) preenchido(s) e assinado(s) e o PDF da nota fiscal anexado para seguir com sua solicitação.</p>
    </div>

    <div class="form-group row">
      <label for="motivo_da_baixa" class="col-sm-4 col-form-label">*Motivo da baixa </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="motivo_da_baixa"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_da_baixa')?.errors?.required && formulario.get('motivo_da_baixa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="formulario_assinado" class="col-sm-4 col-form-label">*Formulário Assinado </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="formulario_assinado" id="formFileMultiple" (change)="save($event)" multiple="multiple">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('formulario_assinado')?.errors?.required && formulario.get('formulario_assinado')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="myFiles.length!=0">
      <div class="card-header">Anexo - Formulário Assinado</div>
      <ul class="list-group list-group-flush" *ngFor="let file of myFiles">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
      </div>
    </div>

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
        <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
            (click)="abrirPipePublico()">Envie por aqui.</span></p>
      </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="accouting"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
