export const tipoTransacao =[
  {
    nome:""
  },
  {
    nome:'Leilão'
  },
  {
    nome:'Transferência entre filiais'
  },
  {
    nome:"Venda"
  },
  {
    nome:'Venda via Leilão'
  },
  {
    nome:'Venda entre empresas do mesmo grupo'
  }
]
