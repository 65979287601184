import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { codigoSap } from './codigo-sap';
import { empresasBaixa } from './empresasBaixa';
import { tipoTransacao } from './tipoTransacao';
import { pipe_fields_id } from './pipe_fields_id';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { tratativas } from './tratativas';


@Component({
  selector: 'app-baixa-imobilizado',
  templateUrl: './baixa-imobilizado.component.html',
  styleUrls: ['./baixa-imobilizado.component.css']
})
export class BaixaImobilizadoComponent implements OnInit {

  formulario!: FormGroup;
  empresas = empresasBaixa;
  tiposTransacao = tipoTransacao;
  codigosSap = codigoSap
  tratativas = tratativas
  myFiles: any[] = [];
  formularios_assinado: any[] = [];
  error_counter: number = 0;
  erro: boolean = false;
  incluirAnexosParm: any[] = [];
  incluirAnexosUrl: any[] = [];
  incluirAnexosParmComprovante: any[] = [];
  incluirAnexosUrlComprovante: any[] = [];

  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: FormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      nome_da_empresa: ['', [Validators.required]],
      tipo_de_transa_o:['',[Validators.required]],
      pdf_nota_fiscal_de_origem_em_anexo: ['', [Validators.required]],
      recebimento_financeiro_atrav_s_de_leil_o: ['', [Validators.required]],
      form_solicitao_emisso_nota_fiscal_anexado_e_preenchido: ['',[Validators.required]],
      form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto: ['', [Validators.required]],
      numero_de_nota_fiscal: ['', [Validators.required]],
      valor_do_faturamento_valor_total_da_venda_dos_itens: ['', [Validators.required]],
      incluirAnexos: ['', [Validators.required]],
      incluirAnexosComprovante: ['', ''],
      tratativa: ['',[]],
      codigo_sap: ['', [Validators.required]],
      data_da_baixa: ['', [Validators.required]],
      numero_do_bem: ['', []],
      codigo_de_ativo_no_sistema: ['', [Validators.required]],
      motivo_da_baixa: ['', [Validators.required]],
      formulario_assinado: ['', [Validators.required]],
      observacao: ['', []]
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email')?.setValue(this.emailApi)
    // this.formulario.get('nome')?.setValue(this.userNameApi)


    // if(this.formulario.getRawValue().email !=''){
    //   this.formulario.controls['email'].disable()
    // }
    // if(this.formulario.getRawValue().email == undefined){
    //   this.formulario.controls['email'].enable()
    // }

    // if(this.formulario.getRawValue().nome !=''){
    //   this.formulario.controls['nome'].disable()
    // }
    // if(this.formulario.getRawValue().nome == undefined){
    //   this.formulario.controls['nome'].enable()
    // }

  }




  enviarFormulario() {

    this.spinner.show()
    const criarBody = async () => {
      await this.getPipesUrl(this.myFiles);
      if (this.myFiles.length > 0) {
        while (this.myFiles.length != this.formularios_assinado.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.myFiles.length > 0) {
        var formularios_assinado2 = ''
        formularios_assinado2 = '['
        for (var i = 0; i < this.formularios_assinado.length; i++) {
          formularios_assinado2 = formularios_assinado2 + '"' + this.formularios_assinado[i] + '",'
        }
        formularios_assinado2 = formularios_assinado2.slice(0, -1)
        formularios_assinado2 = formularios_assinado2 + "]"

      } else {
        formularios_assinado2 = ''
      }

      await this.getPipesUrlIncluirAnexo(this.incluirAnexosParm);
      if (this.incluirAnexosParm.length > 0) {
        while (this.incluirAnexosParm.length != this.incluirAnexosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return;
        }
      }

      if (this.incluirAnexosParm.length > 0) {
        var incluirAnexosUrl2 = '';
        incluirAnexosUrl2 = '[';
        for (var i = 0; i < this.incluirAnexosUrl.length; i++) {
          incluirAnexosUrl2 = incluirAnexosUrl2 + '"' + this.incluirAnexosUrl[i] + '",';
        }
        incluirAnexosUrl2 = incluirAnexosUrl2.slice(0, -1);
        incluirAnexosUrl2 = incluirAnexosUrl2 + ']';
      } else {
        incluirAnexosUrl2 = '';
      }

      await this.getPipesUrlIncluirAnexoComprovante(this.incluirAnexosParmComprovante);
      if (this.incluirAnexosParmComprovante.length > 0) {
        while (this.incluirAnexosParmComprovante.length != this.incluirAnexosUrlComprovante.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return;
        }
      }

      if (this.incluirAnexosParmComprovante.length > 0) {
        var incluirAnexosUrlComprovante2 = '';
        incluirAnexosUrlComprovante2 = '[';
        for (var i = 0; i < this.incluirAnexosUrlComprovante.length; i++) {
          incluirAnexosUrlComprovante2 = incluirAnexosUrlComprovante2 + '"' + this.incluirAnexosUrlComprovante[i] + '",';
        }
        incluirAnexosUrlComprovante2 = incluirAnexosUrlComprovante2.slice(0, -1);
        incluirAnexosUrlComprovante2 = incluirAnexosUrlComprovante2 + ']';
      } else {
        incluirAnexosUrlComprovante2 = '';
      }


      let nome = this.trataInput.removeCaracters(this.formulario.getRawValue().nome)
      let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email)
      let nome_da_empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_da_empresa)
      let tipo_de_transa_o = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_transa_o)
      let tratativa = this.trataInput.removeCaracters(this.formulario.getRawValue().tratativa)
      let codigo_sap = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_sap)
      let data_da_baixa = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_baixa))
      let numero_do_bem = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_bem)
      let codigo_de_ativo_no_sistema = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_de_ativo_no_sistema)
      let motivo_da_baixa = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_da_baixa)
      let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)
      let pdf_da_nota_fiscal_de_origem_est_em_anexo = this.trataInput.removeCaracters(this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo)
      let formul_rio_de_solicita_o_de_emiss_o_de_nota_fiscal_est_anexado_e_preenchido = this.trataInput.removeCaracters(this.formulario.getRawValue().form_solicitao_emisso_nota_fiscal_anexado_e_preenchido)
      let formul_rio_de_autoriza_o_da_contabilidade_para_emiss_o_da_nota_fiscal_est_em_anexo_e_assinado_pela_gest_o = this.trataInput.removeCaracters(this.formulario.getRawValue().form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto)
      let n_mero_de_nota_fiscal = this.trataInput.removeCaracters(
        this.formulario.getRawValue().numero_de_nota_fiscal
      );
      let recebimento_financeiro_atrav_s_de_leil_o =  this.trataInput.removeCaracters(this.formulario.getRawValue().recebimento_financeiro_atrav_s_de_leil_o);
      let valor_do_faturamento_valor_total_da_venda_dos_itens = this.trataInput.removeCaracters(
        this.formulario.getRawValue().valor_do_faturamento_valor_total_da_venda_dos_itens
      );

      let pipe_fields = pipe_fields_id
      let fields_values = [nome, email,
                          nome_da_empresa,
                          tratativa,
                          codigo_sap,
                          data_da_baixa,
                          numero_do_bem,
                          codigo_de_ativo_no_sistema,
                          tipo_de_transa_o,
                          pdf_da_nota_fiscal_de_origem_est_em_anexo,
                          formul_rio_de_solicita_o_de_emiss_o_de_nota_fiscal_est_anexado_e_preenchido,
                          formul_rio_de_autoriza_o_da_contabilidade_para_emiss_o_da_nota_fiscal_est_em_anexo_e_assinado_pela_gest_o,
                          incluirAnexosUrl2,
                          n_mero_de_nota_fiscal,
                          recebimento_financeiro_atrav_s_de_leil_o,
                          incluirAnexosUrlComprovante2,
                          valor_do_faturamento_valor_total_da_venda_dos_itens,
                          motivo_da_baixa,
                          formularios_assinado2,
                          observacao]
      let pipe_id = "302443332"


      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/7lZaeHl1" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.formularios_assinado = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Contabilidade', "Contabilidade - criação de card -- Baixa Imobilizado", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi,this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/7lZaeHl1" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.formularios_assinado = []
        }
      })
    }
    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  saveIncluirAnexo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.incluirAnexosParm.push(files[i])
    }

  }

  saveIncluirAnexoComprovante(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.incluirAnexosParmComprovante.push(files[i])
    }

  }

  save(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.myFiles.push(files[i])
    }

    // console.log(this.myFiles)
    // console.log(this.myFiles.length)

  }



  onChangeLeilao(event: any) {


    const valor = event?.target.value
    if(valor == 'Sim'){
         this.formulario.get('incluirAnexosComprovante')?.setValidators([Validators.required])
         this.formulario.get('incluirAnexosComprovante')?.updateValueAndValidity()

       }
    if(valor !='Sim'){
           this.formulario.get('incluirAnexosComprovante')?.clearValidators()
           this.formulario.get('incluirAnexosComprovante')?.updateValueAndValidity()

    }
    //console.log(valor)
  }

  mostraValor(event: any) {


    const valor = event?.target.value

    //console.log(valor)
  }

  removeFileIncluirAnexo(file: File) {
    const index: number = this.incluirAnexosParm.indexOf(file);
    this.incluirAnexosParm.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.incluirAnexosParm.length == 0) {
      this.formulario.controls['incluirAnexos'].markAsTouched()
      this.formulario.get('incluirAnexos')?.setValue('')
    }
  }

  removeFileIncluirAnexoComprovante(file: File) {
    const index: number = this.incluirAnexosParmComprovante.indexOf(file);
    this.incluirAnexosParmComprovante.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.incluirAnexosParmComprovante.length == 0) {
      this.formulario.controls['incluirAnexosComprovante'].markAsTouched()
      this.formulario.get('incluirAnexosComprovante')?.setValue('')
    }
  }

  async getPipesUrlIncluirAnexo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.incluirAnexosUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/u6uwJWwb" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.incluirAnexosUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getPipesUrlIncluirAnexoComprovante(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.incluirAnexosUrlComprovante.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/u6uwJWwb" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.incluirAnexosUrlComprovante = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  async getPipesUrl(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        } (async () => {
          let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

          var fileUrlTemp = "";
          var startIndex = 0;

          if (urlResposta.indexOf("orgs") > 0) {
            startIndex = urlResposta.indexOf("orgs/");
          } else {
            startIndex = urlResposta.indexOf("uploads/");
          }

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.formularios_assinado.push(fileUrlTemp)
       })();
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/7lZaeHl1" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.formularios_assinado = []
          }
      }
    }
    return new Promise<void>((resolve) => {resolve(); });
  }

  removeFile(file: File) {
    const index: number = this.myFiles.indexOf(file);
    this.myFiles.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.myFiles.length == 0){
      this.formulario.controls['formulario_assinado'].markAsTouched()
      this.formulario.get('formulario_assinado')?.setValue('')
    }
  }


  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()

    this.myFiles = []
    this.formularios_assinado = []

    this.incluirAnexosParm = [];
    this.incluirAnexosUrl = [];

    this.incluirAnexosParmComprovante = [];
    this.incluirAnexosUrlComprovante = [];
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/7lZaeHl1', '_blank')
  }
}
